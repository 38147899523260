/* 🤖 this file was generated by svg-to-ts */
export const waytrBackspace = {
    name: 'backspace',
    data: `<svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.855 14.7692C20.7178 14.7693 20.5819 14.7421 20.4551 14.6892C20.3284 14.6364 20.2132 14.5589 20.1162 14.4612L19.4206 13.7612C19.225 13.5641 19.1152 13.297 19.1154 13.0185C19.1156 12.74 19.2258 12.4729 19.4216 12.2762C19.6175 12.0794 19.883 11.9689 20.1597 11.9691C20.4365 11.9693 20.7019 12.0801 20.8974 12.2772L21.5931 12.9772C21.7389 13.1241 21.8381 13.3112 21.8783 13.5148C21.9185 13.7184 21.8977 13.9294 21.8188 14.1212C21.7398 14.313 21.6061 14.4769 21.4345 14.5922C21.263 14.7076 21.0613 14.7692 20.855 14.7692ZM18.1566 12.0497C18.0195 12.0498 17.8838 12.0227 17.7572 11.9699C17.6305 11.9172 17.5154 11.8399 17.4185 11.7424L14.7159 9.02291C14.619 8.92537 14.5422 8.8096 14.4897 8.68218C14.4373 8.55476 14.4104 8.41821 14.4104 8.28031C14.4104 8.14241 14.4375 8.00587 14.4899 7.87848C14.5424 7.75109 14.6193 7.63534 14.7162 7.53786C14.8132 7.44037 14.9282 7.36305 15.0548 7.31031C15.1815 7.25756 15.3172 7.23044 15.4542 7.23047C15.5913 7.2305 15.727 7.25769 15.8536 7.3105C15.9801 7.3633 16.0952 7.44067 16.1921 7.53821L18.894 10.257C19.04 10.4038 19.1394 10.5909 19.1797 10.7946C19.22 10.9982 19.1994 11.2094 19.1205 11.4013C19.0416 11.5932 18.9079 11.7572 18.7363 11.8726C18.5647 11.988 18.363 12.0497 18.1566 12.0497Z" fill="#6D767E"/>
<path d="M27.9723 21.5697H10.5267C10.0156 21.5684 9.50989 21.4646 9.03907 21.2645C8.56824 21.0644 8.14174 20.7719 7.78444 20.4042L1.28566 13.6842C0.586093 12.968 0.194092 12.0039 0.194092 10.9997C0.194092 9.99545 0.586093 9.0314 1.28566 8.31519L7.78444 1.59519C8.14167 1.22735 8.56816 0.934828 9.039 0.734715C9.50984 0.534601 10.0156 0.430906 10.5267 0.429688H22.1218C22.3986 0.429688 22.664 0.540313 22.8597 0.737226C23.0554 0.934139 23.1653 1.20121 23.1653 1.47969C23.1653 1.75816 23.0554 2.02524 22.8597 2.22215C22.664 2.41906 22.3986 2.52969 22.1218 2.52969H10.5267C10.2943 2.53035 10.0644 2.57761 9.85032 2.66871C9.63627 2.75982 9.44242 2.89294 9.28009 3.06029L2.7827 9.77959C2.46466 10.1051 2.28643 10.5432 2.28643 10.9997C2.28643 11.4561 2.46466 11.8943 2.7827 12.2198L9.28148 18.9398C9.44397 19.1069 9.63786 19.2398 9.85188 19.3308C10.0659 19.4217 10.2958 19.469 10.5281 19.4697H27.9723C28.4335 19.4697 28.8759 19.2853 29.202 18.9571C29.5282 18.6289 29.7114 18.1838 29.7114 17.7197V4.27969C29.7114 3.81556 29.5282 3.37044 29.202 3.04225C28.8759 2.71406 28.4335 2.52969 27.9723 2.52969H25.4185C25.1418 2.52969 24.8764 2.41906 24.6807 2.22215C24.485 2.02524 24.3751 1.75816 24.3751 1.47969C24.3751 1.20121 24.485 0.934139 24.6807 0.737226C24.8764 0.540313 25.1418 0.429688 25.4185 0.429688H27.9723C28.9866 0.430984 29.959 0.837025 30.6763 1.55876C31.3935 2.28049 31.7971 3.259 31.7984 4.27969V17.7197C31.7971 18.7404 31.3935 19.7189 30.6763 20.4406C29.959 21.1624 28.9866 21.5684 27.9723 21.5697Z" fill="#6D767E"/>
<path d="M15.4504 14.7699C15.244 14.77 15.0422 14.7085 14.8705 14.5931C14.6988 14.4777 14.565 14.3137 14.486 14.1218C14.4069 13.9298 14.3863 13.7187 14.4266 13.5149C14.4669 13.3112 14.5663 13.124 14.7124 12.9772L20.1169 7.53889C20.3137 7.34762 20.5773 7.24179 20.8509 7.24418C21.1245 7.24657 21.3862 7.357 21.5796 7.55168C21.7731 7.74636 21.8829 8.00971 21.8852 8.28502C21.8876 8.56032 21.7824 8.82556 21.5924 9.02359L16.1885 14.4619C16.0916 14.5595 15.9766 14.6369 15.8499 14.6898C15.7233 14.7426 15.5875 14.7699 15.4504 14.7699Z" fill="#6D767E"/>
</svg>
`,
};
